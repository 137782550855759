import React from 'react'
// import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
// import Content from '../components/Content'
// import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  cover
}) => (
  <main className="Contact">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={cover}
    />
    <section className="section Contact--Section1">

        <center>
          <strong>お問い合わせ</strong>
          <FormSimpleAjax name="contactGeneral" />
        </center>
    </section>
      {/* </div> */}

    {/* <GoogleMap locations={locations} /> */}
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    template={page.frontmatter.template || false }
    datePosted={page.frontmatter.date || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        cover
      #   featuredImage{
      #   childImageSharp {
      #       fluid(maxWidth: 1200) {
      #         ...GatsbyImageSharpFluid
      #       }
      #   }
      # }
        address
        phone
        email
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`
